
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import headerBanner from '../../home/banner.vue'

export default {
    name: 'promotion',
    components: {
        homeTitlepc,
        headerBanner,
        
    },

    mounted () {
     
    },

    created(){
       

    },

    methods: {
        contactKefu(){
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }


        }
      

    },

    data () {
        return {
            config,
            works1:[
                {
                    img:require('./images/q1.png'),
                    name:'市场分析',
                    show:'分析市场，了解对手，赋予产品竞争力'
                },
                {
                    img:require('./images/q2.png'),
                    name:'产品定位',
                    show:'定位产品优势及客户群体实现爆发式增长'
                },
                {
                    img:require('./images/q3.png'),
                    name:'卖点包装',
                    show:'全方位包装产品，打造产品核心卖点'
                },
                {
                    img:require('./images/q4.png'),
                    name:'热点借势',
                    show:'产品与热点话题集合，扩大产品影响力'
                },
                {
                    img:require('./images/q5.png'),
                    name:'精准营销',
                    show:'精准定位客户，方便客户快速购买'
                },
            ],
            works2:[
                {
                    img:require('./images/s1.png'),
                    name:'专家问诊',
                    show:'为企业提供传播问诊，优势定位、营销建议'
                },
                {
                    img:require('./images/s2.png'),
                    name:'方案定制',
                    show:'根据公司业务范围，营销模式等定制专属营销方案'
                },
                {
                    img:require('./images/s3.png'),
                    name:'报价评估',
                    show:'多道工序审核，合理报价、靠谱方案、效果给力'
                },
                {
                    img:require('./images/s4.png'),
                    name:'急速响应',
                    show:'专家24小时在线，急速响应，省心体验'
                },
               
            ],
            works3:[
                {
                    img:require('./images/t1.png'),
                    name:'命名取名',
                },
                {
                    img:require('./images/t2.png'),
                    name:'活动策划',
                },
                {
                    img:require('./images/t3.png'),
                    name:'营销策划',
                },
                {
                    img:require('./images/t4.png'),
                    name:'SEO',
                },
                {
                    img:require('./images/t5.png'),
                    name:'新媒体运营',
                },
                {
                    img:require('./images/t6.png'),
                    name:'市场调研',
                },
               
            ],
           
            
        }
    }
}
